<template>
  <section class="coming">
    <div class="coming__banner">
      此頁面還在開發中，敬請期待
    </div>
  </section>
</template>
<script>
export default {}
</script>
<style lang="scss">
.coming {
  &__banner {
    width: 100%;
    padding-bottom: 56.25%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    background-image: url('~@/assets/image/Coming/ComingSoonDesktop.jpg');
  }
}

@media (max-width: 768px) {
  .coming {
    &__banner {
      background-image: url('~@/assets/image/Coming/ComingSoonMobile.jpg');
      padding-bottom: 103.9%;
    }
  }
}
</style>
